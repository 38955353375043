.main-data-product {
  padding-top: 200px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}

.first-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.first-heading {
  font-size: 36px;
}

.first-text {
  font-size: 18px;
  max-width: 700px;
}

.first-img {
  max-width: 600px;
}

.second-section-bs {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 50px;
}

.third-section-v {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  gap: 10px;
}

.second-img {
  max-width: 600px;
}

.second-heading-a {
  font-size: 36px;
}

.second-text-a {
  font-size: 18px;
  max-width: 700px;
}

@media screen and (max-width: 1020px) {
  .first-section {
    flex-direction: column;
  }

  .third-section-v {
    flex-direction: column;
  }

  .main-data-product {
    padding: 150px 20px;
  }
  
  .first-img {
    max-width: 350px;
  }

  .second-img {
    max-width: 350px;
  }
}
